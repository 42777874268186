.menubox {
    position: absolute;
    width: 360px;
    top: 80px;
    z-index: 999;
    display: flex;
    padding: 16px;
    align-items: center;
    gap: 8px;
}
.logo {
    width: 100%;
    height: 100%;
    object-fit: cover
}