.box-style {
    border: 2px dashed #cfcfcf;
    border-radius: 8px;
    z-index: 1;
    display: grid;
    justify-items: center;
    margin: 50px;
    padding: 50px;
}
.content {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
}
.cloud-icon {
    font-size: 62px;
    color: #c3c3c3;
    margin-bottom: '30px';
}
.text {
    font-size: 38px;
    font-weight: 500;
    margin-bottom: 10px;
    text-align: center;
}
.sub-text {
    font-size: 24px;
    font-weight: 300;
    margin-bottom: 30px;
    color: #c3c3c3;
    text-align: center;
}
.arrow-icon {
    margin-left: 10px;
    font-size: 11px;
}
