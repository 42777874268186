.pageBox {
   height: 100%;
}

.wizard-checkbox {
   background-size: cover;
   background-position: center;
   color: white;
   height: 100px;
   text-align: center;
   border-radius: 10px;
}


.wizard-checkbox {
   /* box-shadow:
      rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
      rgba(0, 0, 0, 0.06) 0px 2px 4px -1px; */
   transform: none;
   transition: transform 0.3s ease-in-out;
}


/* Hide scrollbar for Chrome, Safari and Opera */
.wizard-grid::-webkit-scrollbar {
   display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.wizard-grid {
   -ms-overflow-style: none;
   /* IE and Edge */
   scrollbar-width: none;
   /* Firefox */
}

.arch-image-text {
   display: grid;
   align-items: center;
   height: 100%;
   width: 100%;
   font-weight: bold;
   aspect-ratio: 3/2;
   color: black;
   border-radius: inherit;
}

div.archt.wizard-grid label:nth-child(1),
div.arch-selector:nth-child(1) {
   background: linear-gradient(#F8BBD0, #E1BEE7);
}

div.archt.wizard-grid label:nth-child(2),
div.arch-selector:nth-child(2) {
   background: linear-gradient(#B2DFDB, #BBDEFB);
}

div.archt.wizard-grid label:nth-child(3),
div.arch-selector:nth-child(3) {
   background: linear-gradient(#FFCC80, #FFF59D);
}

div.archt.wizard-grid label:nth-child(4),
div.arch-selector:nth-child(4) {
   background: linear-gradient(#E1BEE7, #90CAF9);
}