/* Sidebar.css */

.chakra-tabs__tab-panel {
    overflow-y: auto;
    max-height: inherit;
}

.chakra-tabs__tab-panel::-webkit-scrollbar-track {
    background-color: #f5f5f5;
    border-radius: 30px;
}

.chakra-tabs__tab-panel::-webkit-scrollbar {
    width: 0.3rem;
    border-radius: 30px;
}

.chakra-tabs__tab-panel::-webkit-scrollbar-thumb {
    border: 2px solid #555555;
    border-radius: 30px;
}

/* .chakra-tabs__tab-panel {
    max-height: calc(100vh - 200px);
} */

ul {
    list-style-type: none;
    padding-left: 0;
}

li {
    margin-bottom: 5px;
}

details > summary {
    list-style: none;
}

summary::-webkit-details-marker {
    display: none;
}

summary::after {
    content: ' ►';
}

details[open] summary:after {
    content: ' ▼';
}
