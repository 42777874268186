:root {
    font-family: 'Poppins, sans-serif';
}

body {
    display: flex;
    flex-direction: column;
    min-height: 100vh; /* ensure body takes up full viewport height */
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: #d5d5d5 #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 0.3rem;
}

*::-webkit-scrollbar-track {
    background: #d5d5d5;
}

*::-webkit-scrollbar-thumb {
    background-color: #d5d5d5;
    border-radius: 10px;
    border: 3px solid #828282;
}

.wrapper {
    flex: 1; /* make wrapper take up remaining vertical space */
}
.myDiv {
    font-size: 20px;
    text-align: left;
}

.footer {
    position: fixed;
    bottom: 0;
    width: 100%;
}
.Modal {
    width: '100%';
    height: '90%';
    align-items: 'center';
    justify-content: 'center';
}
.input-container {
    margin-bottom: 1rem; /* or any other value you prefer */
}

html,
body,
#root {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.dndflow {
    flex-direction: column;
    display: flex;
    flex-grow: 1;
    height: 100%;
}

.screen-body {
    padding-top: 72px;
    height: 100%;
}

.dndflow aside {
    border-right: 1px solid #eee;
    padding: 15px 10px;
    font-size: 12px;
    background: #fcfcfc;
}

.dndflow aside .description {
    margin-bottom: 14px;
}

.sideBar::-webkit-scrollbar {
    width: 0.1em;
    background: transparent;
}

.sideBar::-webkit-scrollbar-thumb {
    background-color: transparent;
}

.dndflow .dndnode {
    height: 40px;
    padding: 4px;
    border: 1px solid #1a192b;
    border-radius: 15px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: grab;
}

.dndflow .dndnode.input {
    border-color: #0041d0;
    padding: 2px;
}

.dndflow .dndnode.output {
    border-color: #ebaf24;
    padding: 20px;
    font-size: 20px;
}

.react-flow__edge.success .react-flow__edge-path,
.react-flow__edge:focus .react-flow__edge-path,
.react-flow__edge:focus-visible .react-flow__edge-path {
    stroke: black;
}

.react-flow__edge.grey .react-flow__edge-path,
.react-flow__edge:focus .react-flow__edge-path,
.react-flow__edge:focus-visible .react-flow__edge-path {
    stroke: #bcbaba;
}

.react-flow__edge.warning .react-flow__edge-path,
.react-flow__edge:focus .react-flow__edge-path,
.react-flow__edge:focus-visible .react-flow__edge-path {
    stroke: red;
}

.react-flow__edge.selected .react-flow__edge-path,
.react-flow__edge:focus .react-flow__edge-path,
.react-flow__edge:focus-visible .react-flow__edge-path {
    stroke: #0041d0 !important;
}

.dndflow .reactflow-wrapper {
    flex-grow: 1;
    height: 100%;
    padding: 2px;
}

.react-flow__attribution a {
    text-decoration: none;
    pointer-events: none;
    color: #ffffff;
}

.react-flow__controls {
    margin-left: 97.5%;
}

.dndflow .selectall {
    margin-top: 10px;
}

.react-flow__node.react-flow__node-ResizableNode,
.react-flow__node.react-flow__node-selectorNode,
.react-flow__node.react-flow__node-selectorNode1,
.react-flow__node.react-flow__node-selectorNode2,
.react-flow__node.react-flow__node-selectorNode3,
.react-flow__node.react-flow__node-selectorNode4,
.react-flow__node.react-flow__node-selectorNode5,
.react-flow__node.react-flow__node-selectorNode6,
.react-flow__node.react-flow__node-selectorNode7 {
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 50px;
    word-break: break-all;
}

@media screen and (min-width: 768px) {
    .dndflow {
        flex-direction: row;
    }

    .dndflow aside {
        width: 20%;
        max-width: 300px;
    }
}

.react-flow__node-default.selectable.selected,
.react-flow__node-input.selectable:focus {
    box-shadow: none !important;
    border: 1.5px solid rgb(49, 130, 206) !important;
}

.architectures-section {
    animation: backgroundAnimation 20s linear infinite;
    background-size: 300% 100%;
    background-image: linear-gradient(to right, #f0f8ff, #c3e2ff, #f0f8ff);
}

/* .create-architecture::after:hover {
    box-shadow: mg;
    transform: none;
    transition: transform 0.3s ease-in-out;
} */

.create-architecture:not([disabled]):hover,
.architecture-card:not([disabled]):hover,
.image-select:not([disabled]):hover {
    box-shadow:
        rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
        rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    transform: scale(1.05);
    transition: transform 0.3s ease-in-out;
}

.create-architecture:not([disabled]):active,
.architecture-card:not([disabled]):active,
.image-select:not([disabled]):active {
    /* background-color: #3e8e41; */
    /* box-shadow: 0 5px #666; */
    transform: translateY(4px);
}

.create-architecture,
.architecture-card,
.total-architecture,
.image-select {
    box-shadow:
        rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
        rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    transform: none;
    transition: transform 0.3s ease-in-out;
}

.not-selectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.image-text {
    display: grid;
    align-items: center;
    height: 100%;
    font-size: xl;
    font-weight: bold;
    background-color: #0000002e;
    color: black;
    backdrop-filter: blur(2px);
    border-radius: inherit;
}

.image-select {
    background-size: cover;
    background-position: center;
    color: white;
    height: 100px;
    text-align: center;
    border-radius: 10px;
}

body {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    /* ensure body takes up full viewport height */
}

.wrapper {
    flex: 1;
    /* make wrapper take up remaining vertical space */
}

.myDiv {
    font-size: 20px;
    text-align: left;
}

.footer {
    position: fixed;
    bottom: 0;
    width: 100%;
}

.Modal {
    width: '100%';
    height: '90%';
    align-items: 'center';
    justify-content: 'center';
}

.input-container {
    margin-bottom: 1rem;
    /* or any other value you prefer */
}

html,
body,
#root {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: sans-serif;
}

.dndflow {
    flex-direction: column;
    display: flex;
    flex-grow: 1;
}

.dndflow aside {
    border-right: 1px solid #eee;
    padding: 15px 10px;
    font-size: 12px;
    background: #fcfcfc;
}

.description {
    margin-bottom: 14px;
}

.dndflow aside .sideBlock::-webkit-scrollbar {
    width: 0.1em;
    background: transparent;
}

.dndflow aside .sideBlock::-webkit-scrollbar-thumb {
    background-color: transparent;
}

.dndnode {
    height: 40px;
    padding: 4px;
    border: 1px solid #1a192b;
    border-radius: 2px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: grab;
}

.dndflow .dndnode.input {
    border-color: #0041d0;
    padding: 2px;
}

.dndflow .dndnode.output {
    border-color: #ebaf24;
    padding: 20px;
    font-size: 20px;
}

.dndflow .reactflow-wrapper {
    flex-grow: 1;
    height: 100%;
    padding: 2px;
}

.react-flow__attribution a {
    text-decoration: none;
    pointer-events: none;
    color: #ffffff;
}

.dndflow .selectall {
    margin-top: 10px;
}

.react-flow__node.react-flow__node-ResizableNode,
.react-flow__node.react-flow__node-selectorNode,
.react-flow__node.react-flow__node-selectorNode1,
.react-flow__node.react-flow__node-selectorNode2,
.react-flow__node.react-flow__node-selectorNode3,
.react-flow__node.react-flow__node-selectorNode4,
.react-flow__node.react-flow__node-selectorNode5,
.react-flow__node.react-flow__node-selectorNode6,
.react-flow__node.react-flow__node-selectorNode7 {
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
}

.refArchNode {
    box-shadow:
        rgba(0, 0, 0, 0.24) 0px 4px 6px -1px,
        rgba(0, 0, 0, 0.12) 0px 2px 4px -1px;
    transform: none;
    transition: transform 0.3s ease-in-out;
    border-radius: 10px;
}

.refArchNode:hover {
    box-shadow:
        rgba(0, 0, 0, 0.3) 0px 10px 15px -3px,
        rgba(0, 0, 0, 0.15) 0px 4px 6px -2px;
    transform: scale(1.05);
    transition: transform 0.3s ease-in-out;
}

@media screen and (min-width: 768px) {
    .dndflow {
        flex-direction: row;
    }

    .dndflow aside {
        width: 20%;
        max-width: 250px;
    }
}

.react-flow__node-default.selectable.selected,
.react-flow__node-input.selectable:focus {
    box-shadow: none !important;
    border: 1.5px solid rgb(49, 130, 206) !important;
}

/* .sideBar {
  overflow: hidden;
  width: 0;
  opacity: 0;
  transition: width 0ms 400ms, opacity 400ms 0ms;
} */

.sideBar {
    height: auto;
    opacity: 1;
    animation: fadeIn 10s;
}

.download-image.react-flow {
    background: #1a365d;
}

.download-image .react-flow__node {
    width: 50px;
    height: 50px;
    color: white;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    border-color: white;
}

.download-image .react-flow__node-custom {
    font-size: 12px;
    background: #eee;
    border: 1px solid #555;
    border-radius: 5px;
    text-align: center;
    padding: 10px;
}

.download-image .react-flow__node-custom .react-flow__handle-right {
    transform: none;
}

.refArchNode {
    box-shadow:
        rgba(0, 0, 0, 0.24) 0px 4px 6px -1px,
        rgba(0, 0, 0, 0.12) 0px 2px 4px -1px;
    transform: none;
    transition: transform 0.3s ease-in-out;
    border-radius: 10px;
}

.refArchNode:hover {
    box-shadow:
        rgba(0, 0, 0, 0.3) 0px 10px 15px -3px,
        rgba(0, 0, 0, 0.15) 0px 4px 6px -2px;
    transform: scale(1.05);
    transition: transform 0.3s ease-in-out;
}

.chakra-breadcrumb__list {
    align-items: baseline;
}

.required::after {
    content: ' *';
    color: red;
}

.pagination-container {
    position: relative;
    margin-top: 20px;
}

.pagination {
    display: flex;
    list-style: none;
    padding: 0;
    justify-content: center;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}

.page-item {
    margin: 0 5px;
}

.page-link {
    display: block;
    padding: 10px 15px;
    color: #333;
    background-color: #fff;
    border: 1px solid #ddd;
    text-decoration: none;
    border-radius: 4px;
    cursor: pointer;
}

.page-link:hover {
    background-color: #f5f5f5;
}

.page-link.active {
    background-color: #007bff;
    color: #fff;
    border: 1px solid #007bff;
}

.custom-table {
    border-collapse: collapse;
    width: 100%;
}

.custom-table tr:last-child {
    border-bottom: none;
}

.chakra-button {
    min-height: 48px;
}

.service-header {
    cursor: pointer;
    font-size: 20px;
    display: flex;
    align-items: center;
    transition: background-color 0.3s ease;
    margin-bottom: 5px;
}

.toggle-icon {
    color: lightgrey;
    font-size: 12px;
    margin-right: 10px;
}

.sidebar-icon-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 6px;
    margin-left: 20px;
}

.image-row {
    display: flex;
    justify-content: flex-start;
    gap: 20px; 
}

.sidebar-icon-box {
    width: 110px; 
    height: 60px; 
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: white;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    cursor: grab;
    transition: transform 0.2s ease;
    margin-bottom: 15px; 
}

.sidebar-icon-box:hover {
    transform: scale(1.05);
}

.sidebar-icon-image {
    width: 90px;
    height: 80px;
    object-fit: contain;
}

.group-image {
    width: 200px;
    height: 200px;
    object-fit: contain;
    margin-top: 12px;
}

.eureka-image {
    width: 80px;
    height: 50px;
    object-fit: contain;
}

.eck-image {
    width: 80px;
    height: 50px;
    object-fit: contain;
}

.docusaurus-image {
    width: 100px;
    object-fit: contain;
}