.page {
    min-height: calc(100vh - 64px);
    background-color: rgb(255, 255, 255);
    align-items: center;
    padding-top: 100px;
    padding-bottom: 120px;
    padding-inline: 200px;
    flex-direction: column;
}

.background {
    align-items: center;
    background-color: black;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    height: 330px;
    justify-content: space-evenly;
    padding: 40px;
    position: relative;
    width: 1200px;
    text-align: center;
}

.box {
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    width: 1200px;
    flex-wrap: wrap;
    align-content: baseline;
}

.frame {
    align-items: center;
    background-color: #e6eaff;
    display: flex;
    gap: 10px;
    height: 65px;
    justify-content: center;
    min-width: 65px;
    padding: 10px;
    position: relative;
    width: 100%;
}

.frame .combined-shape-wrapper {
    height: 25px;
    position: relative;
    width: 25px;
}

.frame .combined-shape {
    height: 15px;
    left: 5px;
    position: absolute;
    top: 5px;
    width: 15px;
}

.arch-selector {
    height: 184px;
    width: 264px;
    background-color: #e6eaff;
    border-radius: 10px;
    border: 1px solid var(--Standard-Outline, #ebeef1);
    background: var(--White, #fff);

    /* Medium Shadow */
    box-shadow: 0px 1px 8px 0px rgba(14, 30, 47, 0.03);

    /* Layout */
    display: flex;
    padding: 30px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 25px;
}

.arch-selector:hover {
    box-shadow:
        rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
        rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    transform: scale(1.05);
    transition: transform 0.3s ease-in-out;
}

.arch-icon {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 17px;
    align-self: stretch;
}

.arch-text {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ref-card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    flex: 1 0 0;
    border-radius: 10px;
    /* border: 2px solid black; */
    /* border: 1px solid var(--Standard-Outline, #ebeef1); */
    box-shadow:
        rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
        rgba(0, 0, 0.5, 0.05) 0px 4px 6px -2px;
    background: var(--White, #fff);
}

.ref-card:hover {
    border-radius: 10px;
    border: 2px solid #fcd400;
    background: #fcfcfc;
    box-shadow:
        rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
        rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    transform: scale(1.05);
    transition: transform 0.3s ease-in-out;
}
